<template>
  <Page v-bind:background="'bg-created'" v-bind:questionOptions="false">
    <template v-slot:contents>
      <div class="pt700">
        <div class="col-lg-12">
          <div class="loadProgress"></div>
          <img
            alt="your will"
            class="will-created-img"
            src="https://mylastwill.s3.amazonaws.com/static/img/will-holder.png"
          />
          <div v-bind:class="{ doc: willHtml }">
            <div class="typing-wrapper">
              <div class="typing">
                <div class="text-cover"></div>
                <div v-html="willHtml"></div>
              </div>
            </div>
          </div>
        </div>
        <div class="container">
          <div class="row pt30 align-center">
            <div class="col-lg-8 col-lg-offset-2">
              <div class="heading">
                <p>
                  {{ createText }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-slot:navButtons>
      <NavQuestionButtons
        v-bind:validated="validateButtons"
        v-bind:forwardText="'Next'"
        v-bind:forwardLink="forwardLink"
        v-bind:backLink="'/home'"
        v-bind:center="true"
      ></NavQuestionButtons>
    </template>
  </Page>
</template>

<script>
import NavQuestionButtons from '@/common/ui/NavQuestionButtons'
import Page from '@/common/ui/Page'
import router from '@/router/index'
import { willStringHelpers } from '@/common/mixins/willStringHelpers'
import { sectionDescriptions } from '@/common/mixins/sectionDescriptions'
import { http } from '@/services'
import { checkoutHelpers } from '@/common/mixins/checkoutHelpers'

export default {
  name: 'CreateWillPage',
  mixins: [willStringHelpers, sectionDescriptions, checkoutHelpers],
  components: {
    NavQuestionButtons,
    Page
  },
  mounted() {
    if (this.$store.getters.progressSummary.percentageComplete !== 100) {
      router.push('/will_not_ready').catch((error) => {
        console.log(error)
      })
    } else {
      this.$store.dispatch('willPreviewFetch')
    }
  },
  beforeRouteLeave(to, from, next) {
    this.saveSectionDescriptions().then(this.renderWill)
    this.$store
      .dispatch('checkoutBasketSave', {
        printing: true
      })
      .then(this.saveCheckoutOptions())
    next()
  },
  computed: {
    willHtml() {
      if (
        this.$store.getters.willPreview &&
        this.$store.getters.willPreview.will_preview
      )
        return this.$store.getters.willPreview.will_preview
      return null
    },
    forwardLink() {
      if (this.$store.getters.checkoutBasket.active_subscription)
        return '/checking'
      return '/storage'
    },
    validateButtons() {
      return !!this.willHtml
    },
    createText() {
      if (this.$store.getters.willUpdate)
        return 'Your updated Will is almost ready, the next step is to confirm your options.'
      return 'Your Will is almost ready, the next step is to confirm your options.'
    }
  },
  methods: {
    renderWill() {
      http
        .get('/wills/api/render_will', {
          params: { id: this.$store.getters.willPreview.will_ob.id }
        })
        .then((response) => {
          console.log(response)
        })
        .catch((error) => {
          console.log(error)
        })
    }
  }
}
</script>
